import React, { useEffect, useState } from "react";
import axios from "axios";
import Banner from "../../components/Navbar/Banner";
import "./ccdt.css";
import "./CCDT_BAITEST.css";

const Ctdt = ({ userRole, MaNV, usePhongBan }) => {
  const [subjects, setSubjects] = useState([]);
  const [scores, setScores] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubjects = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://server.congtien.com.vn/monhoc/role/${userRole}/${usePhongBan}`
        );
        setSubjects(response.data);
        // Fetch scores for each subject
        const scoresPromises = response.data.map((subject) =>
          axios.get(
            `https://server.congtien.com.vn/ketqua/${MaNV}/${subject._id}`
          )
        );

        const scoresResponses = await Promise.all(scoresPromises);
        const scoresData = scoresResponses.reduce((acc, curr, index) => {
          acc[response.data[index]._id] = curr.data.Diem;
          return acc;
        }, {});

        setScores(scoresData);
      } catch (error) {
        console.error("Lỗi khi gọi API:", error);
        setError("Không thể tải dữ liệu. Vui lòng thử lại sau.");
      } finally {
        setLoading(false);
      }
    };

    fetchSubjects();
  }, [userRole]);

  const handleSubjectClick = (_id, link) => {
    localStorage.setItem("idmonhoc", _id);
    window.open(link + "?a=" + _id, "_top");
  };

  return (
    <>
      <Banner
        imageUrl={"https://i.imgur.com/4wujx9I.png"}
        text={"Tài liệu Lean Online"}
      />
      <div className="container">
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && (
          <table className="subjects-table">
            <thead>
              <tr>
                <th>Tên Môn Học</th>
                <th>Mã Môn Học</th>
                <th>Điểm</th>
                <th>Hành Động</th>
              </tr>
            </thead>
            <tbody>
              {subjects.map((subject) =>
                subject.trangthai === 1 ? (
                  <tr key={subject._id}>
                    <td>{subject.TenMonHoc}</td>
                    <td>{subject.MaMonHoc}</td>
                    <td>{scores[subject._id] || "Chưa có điểm"}</td>
                    <td>
                      <a
                        onClick={() =>
                          handleSubjectClick(subject._id, subject.LinkMonHoc)
                        }
                        href="#"
                      >
                        Truy cập để học và làm bài test
                      </a>
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default Ctdt;
